
@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova-Light-webfont.woff) format('woff');
    font-weight: 300;  /* light */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/Proxima_Nova_Sbold-webfont.woff) format('woff');
    font-weight: 600;  /* semibold */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova.otf) format('opentype');
    font-weight: 400;  /* normal */
    font-style: normal;
    font-display: swap;
}


body {
    font-family: "ProximaNova", sans-serif !important;
}

.bold {
  font-weight: bold;
}

#theme-toggler {z-index: 166000; cursor: pointer; font-size:1.5em; position: fixed; bottom: 2em; left: 2em; width: 50px; height: 50px; opacity: 0; transition: opacity 0.5s ease-in-out; } #theme-toggler.show { opacity: 1; } @keyframes bounce { 0%, 20%, 60%, 100% { -webkit-transform: translateY(0); transform: translateY(0); } 40% { -webkit-transform: translateY(-10px); transform: translateY(-10px); } 80% { -webkit-transform: translateY(-5px); transform: translateY(-5px); } } #theme-toggler.animate { animation: bounce 2s;  animation-iteration-count: 3; }

:root {
  --bs-btn-hover-border-color: var(--primary-text-theme-color);
  --bs-emphasis-color: var(--primary-text-theme-color);
  --bs-link-color-rgb: 51, 122, 183; /* Custom Blue 1 */
  --bs-link-hover-color-rgb: 26, 82, 118; /* Custom Blue 2 */

 /* --bs-body-font-size: 1.3em; */
}

[data-theme='dark'] text {
    fill: white !important;
}
.form-check .form-check-input {
  margin-left: 0;
}
[data-theme='light']:root {
  /* Light theme colors */
  --primary-fill-color: black;
  --primary-stroke-color: black;
  --primary-bg-theme-color: white;
  --primary-text-theme-color: black;
  --bs-nav-link-color: #121212;
  --bs-navbar-color: #121212;
  --primary-opacity: 0.1;
  --secondary-text-theme-color: #4B646F;
  --primary-btn-bg: #37769a;
  --bs-btn-hover-bg: #37769a;
  --primary-btn-hover: #154562;
  --progress-bar-bg: #4B646F !important;
  --progress-bar-color: white !important;
  --primary-border-theme-color: #ececec;
}

[data-theme='dark']:root {
  --secondary-text-theme-color: #82aec1;
  --primary-opacity: 0.3;
  --primary-fill-color: #e0e0e0;
  --primary-stroke-color: #e0e0e0;
  --primary-bg-theme-color: #121212;
  --primary-text-theme-color: #e0e0e0;
  --bs-bg-opacity: 1;
  --bs-nav-link-color: #e0e0e0 !important;
  --bs-navbar-color: #e0e0e0;
  --bs-light-rgb: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
  --bs-white-rgb: transparent !important;
  --bs-emphasis-color-rgb: #e0e0e0;
  --bs-secondary-color: #ccc;
  --bs-body-bg: #121212;
  --bs-table-color: #e0e0e0;
  --bs-card-color: #eee;
  --bs-dropdown-link-color: #e0e0e0;
  --bs-body-color: #eee;
  --primary-btn-bg: #1d4965;
  --primary-btn-hover: #1d4965;
  --bs-btn-hover-bg: #154562;
  --progress-bar-bg: #1d4965;
  --progress-bar-color: #e0e0e0;
  --primary-border-theme-color: #434343;

}
.progress-bar {

  background-color: var(--progress-bar-bg) !important;
  color: var(--progress-bar-color) !important;
}

[data-theme="light"] body {
    background-color: var(--primary-bg-theme-color) !important;
    color: var(--primary-text-theme-color) !important;
    transition: background-color 2.3s ease;

  }
[data-theme="dark"] .bg-light {
    transition: background-color 2.3s ease !important;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}
[data-theme="dark"] body {
    background-color: var(--primary-bg-theme-color) !important;
    color: var(--primary-text-theme-color) !important;
    transition: background-color 2.3s ease;
    --bs-nav-link-color: var(--bs-nav-link-color) !important;
}
@media (prefers-color-scheme: dark) {
 [data-theme='dark']:root {
  --primary-opacity: 0.3;
  --primary-fill-color: #e0e0e0;
  --primary-stroke-color: #e0e0e0;
  --primary-bg-theme-color: #121212;
  --primary-text-theme-color: #e0e0e0;
  --bs-nav-link-color: #e0e0e0 !important;
  --bs-navbar-color: #e0e0e0;
  --bs-light-rgb: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
  --bs-white-rgb: transparent !important;
  --bs-emphasis-color-rgb: #e0e0e0;
  --bs-card-color: #eee;
  }
}
[data-theme="dark"] .dropdown-menu {
  --bs-dropdown-link-color: #e0e0e0;
  --bs-dropdown-link-hover-color: #000;
}
.card{
  --bs-card-color: var(--primary-text-theme-color);
}
[data-theme="dark"] .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: #eee;
}

.svg-line, g#layer-MC0 path, g#layer1 path {
  fill: var(--primary-fill-color) !important;
  stroke: var(--primary-stroke-color) !important;
}

.svg-line {
  opacity: var(--primary-opacity) !important;
}

input#date_range {
    padding: 0.3em;
    font-size: 1.3em;
    text-align: center;
    border-radius: 7px;
    color: var(--secondary-text-theme-color);
    background: var(--primary-bg-theme-color);
}

#dakwakBrain{ width: 100%;}
#dakwakLogo {
  width: 2.5em;
  height: 2.5em;
}

a {
    text-decoration: none; /* This removes the underline */
    color: var(--primary-text-theme-color); /* Set a distinct color for links */
}

a:hover {
    color: #5fa0e6; /* Change color on hover */
    text-decoration: none; /* This removes the underline */
}

.btn:hover
{
    --bs-btn-hover-color: #eee;
}

.btn-dakwak
{
    --bs-btn-color: #fff;
    --bs-btn-bg: #112029;
    --bs-btn-border-color: #112029;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3b3b3b;
    --bs-btn-hover-border-color: #3b3b3b;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #112029;
    --bs-btn-active-border-color: #112029;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ccc;
    --bs-btn-disabled-border-color: #eee;
    font-weight: 800;
    opacity: 1;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-btn-bg);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-btn-hover);
    --bs-btn-hover-border-color: #1a4965;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-btn-hover);;
    --bs-btn-active-border-color: #1a4965;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ccc;
    --bs-btn-disabled-border-color: #eee;
    font-weight: 800;
    opacity: 1;
}

.btn-dark{
    font-weight: bold;
}
div#fullpage {
    min-height: 44rem;
    margin-bottom: 5em !important;
}
.form-horizontal {
    width: 30rem;
}
.dropdown-menu {
  --bs-dropdown-link-active-bg: #000000;
}
.rc-anchor-light {
    background: var(--primary-bg-theme-color) !important;
    color: var(--primary-text-theme-color) !important;
}
[class^="daklive-"] {
    white-space: pre-wrap;
    word-spacing: normal;
    animation: fadeIn 800ms ease-out;
}
.lead {
    font-size: 1.5em !important;
}
@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes swingwing {
  0% {
    transform: rotate(10deg);
    fill: black;
  }
  50% {
    transform: rotate(-10deg);
    fill: gray;

  }
  100% {
    transform: rotate(10deg);
    fill: black;
  }
}
#path46 {
  transform-origin: 50% 0;
  animation: swingwing 4s infinite;
}
.toast {
  position: fixed;
  top: 2rem;
  right: 1rem;
  z-index: 1050;
}
.toast-header strong {
  font-size: 1.3rem;  /* Increase font size */
}

.toast-body {
  font-size: 1.1rem;  /* Increase font size */
}
.toast-header strong, .toast-body {
  color: #efefef;
}

.toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: ;
    --bs-toast-bg: #37769a;
    --bs-toast-header-color: var(--secondary-text-theme-color);
    --bs-toast-header-bg: #37769a;
    --bs-toast-header-border-color: var(--bs-border-color-translucent);
    background-color: var(--bs-toast-bg);
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    border-radius: var(--bs-toast-border-radius);
    box-shadow: var(--bs-toast-box-shadow);
    color: var(--bs-toast-color);
    font-size: var(--bs-toast-font-size);
    max-width: 100%;
    pointer-events: auto;
    width: var(--bs-toast-max-width);
}

.toggle-price{
  border-color: #37769a;
}
.toggle-price:checked{
  background-color: #204965;

}
.chosen-container-multi .chosen-choices .search-field {
   min-width: 30em !important;
}
.form-control:disabled {
color: #212529 !important;
}

.selected-plan {
    position: absolute;
    font-size: 1.8em;
}
