.accordion-heading{
  cursor: pointer;
  background-color: var(--bs-light-rgb);
  /*padding: 15px;*/
  border-bottom: 0;
}
.accordion-heading a{
  color:var(--primary-text-theme-color);
  font-weight: 700;
  font-size: 1.2em;
}
.accordion-heading a:hover, .accordion-heading a:active, .accordion-heading a:visited{
  color:#7ea3d9;
  font-weight: 700;
  font-size: 1.2em;
}

.accordion-inner {
  background: var(--bs-light-rgb);
  border-top:0;
  font-size: 1.2em;
  color: var(--primary-text-theme-color);
}
.icon-chevron-right {
  opacity: 0.7;
}

.well
{
  margin-top: 5em;

}
.accordion-group {
    background: var(--bs-light-rgb);
    border-radius: 3px;
    padding: 1em;
    margin-bottom: 10px;
    opacity: 1;
    font-size: 1em;
    line-height: 2em;
}
